export default function Footer() {
  return (
    <footer className="bg-primaryColor text-white py-8">
      <div className="flex justify-center">
        <nav className="container flex justify-center py-8">
          <ul className="flex flex-col md:flex-row gap-8 md:gap-20 text-center">
            <li>
              <a href="#story">Nuestra historia</a>
            </li>
            <li>
              <a href="#weeding-list">Listas de Bodas</a>
            </li>
            <li>
              <a href="/">Itinerario</a>
            </li>
            <li>
              <a href="https://forms.gle/GYN4egT2xjPQBFVr8"
                  target="_blank"
                  rel="noreferrer"
              >RSVP</a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="flex justify-center text-bgColor text-center py-8 opacity-50 font-secondaryFont font-light">
        <p>
          Términos y Condiciones | Política de Privacidad
          <span className="block"> © Copyright - MOMO Studio 2024</span>
        </p>
      </div>
    </footer>
  );
}
