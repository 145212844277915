import React from "react";
import "./styles.css";
import logo from "../../logo.svg";

export default function Sidenav({ sideNavOpen, toggleSideNav }) {
  return (
    <div className="sidebar-menu-wrapper text-primaryColor">
      <div
        className={`mobile_sidebar_menu bg-bgColor font-primaryFont ${
          sideNavOpen && "active"
        }`}
      >
        <button type="button" onClick={toggleSideNav} className="close_btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="about_content mb-16">
          <div className="brand_logo mb-4 flex w-2/3">
            <a href="/" className="text-3xl font-extrabold">
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          </div>
        </div>

        <div className="menu_list mb_60 clearfix">
          <ul className="ul_li_block clearfix">
            <li>
              <a href="#invitation" onClick={toggleSideNav}>
                Invitación
              </a>
            </li>
            <li>
              <a href="#story" onClick={toggleSideNav}>
                Nuestra historia
              </a>
            </li>
            <li>
              <a href="#weeding-list" onClick={toggleSideNav}>
                Listas de Bodas
              </a>
            </li>
            <li>
              <a href="https://forms.gle/GYN4egT2xjPQBFVr8"
                  target="_blank"
                  rel="noreferrer"
                  onClick={toggleSideNav}>
                RSVP
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-8" />
        <p>
          <a href="/pets" className="text-xl">
            Gracias por ser parte de nuestra Historia
          </a>
        </p>
      </div>
      <div
        role="button"
        tabIndex="0"
        onClick={toggleSideNav}
        className={`overlay bg-slate-800/45 ${sideNavOpen && "active"}`}
      ></div>
    </div>
  );
}
