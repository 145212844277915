import React from "react";
import logo from "../../logo.svg";

export default function Header({ toggleSideNav }) {
  return (
    <header className="App-header flex justify-center">
      <nav className="container py-8 text-primaryColor/60">
        <ul className="flex relative justify-center gap-20 text-xl items-center text-center font-light">
          <li className="hidden md:block">
            <a href="#invitation">Invitación</a>
          </li>
          <li className="hidden md:block">
            <a href="#story">Nuestra historia</a>
          </li>
          <li className="flex justify-center">
            <img src={logo} className="App-logo" alt="logo" />
          </li>
          <li className="hidden md:block">
            <a href="#weeding-list">Listas de Bodas</a>
          </li>
          <li className="hidden md:block">
            <a href="https://forms.gle/GYN4egT2xjPQBFVr8"
                  target="_blank"
                  rel="noreferrer"
            >RSVP</a>
          </li>
          <li className="md:hidden absolute right-4">
            <button className="block" onClick={toggleSideNav}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}
