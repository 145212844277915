import { useEffect, useState } from "react";

export function useImagesCloudfare() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages([
      {
        id: "e27c8fac-2ba0-49e8-2b2c-67497abc1b00",
        filename: "IMG-20240813-WA0118.jpg",
        uploaded: "2024-09-01T23:00:31.851Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/e27c8fac-2ba0-49e8-2b2c-67497abc1b00/public",
        ],
      },
      {
        id: "456a1624-fd30-4ffa-31cc-8f6df4275200",
        filename: "IMG-20240813-WA0184.jpg",
        uploaded: "2024-09-01T23:00:31.465Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/456a1624-fd30-4ffa-31cc-8f6df4275200/public",
        ],
      },
      {
        id: "cdfb2e76-7262-4303-6c9c-bb2eaf9edf00",
        filename: "IMG-20240813-WA0182.jpg",
        uploaded: "2024-09-01T23:00:30.611Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/cdfb2e76-7262-4303-6c9c-bb2eaf9edf00/public",
        ],
      },
      {
        id: "639b508f-2520-49f8-a9cb-254bf3933300",
        filename: "2.jpg",
        uploaded: "2024-09-01T23:00:29.958Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/639b508f-2520-49f8-a9cb-254bf3933300/public",
        ],
      },
      {
        id: "c3e0351d-9cb9-4063-4a70-9452582bed00",
        filename: "IMG-20240813-WA0186.jpg",
        uploaded: "2024-09-01T23:00:29.667Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/c3e0351d-9cb9-4063-4a70-9452582bed00/public",
        ],
      },
      {
        id: "93958944-d7fb-4a70-720a-35e716880800",
        filename: "IMG-20240813-WA0174.jpg",
        uploaded: "2024-09-01T23:00:29.634Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/93958944-d7fb-4a70-720a-35e716880800/public",
        ],
      },
      {
        id: "0c1d0590-4aa3-4cd3-65d8-028f771b5c00",
        filename: "IMG-20240813-WA0180.jpg",
        uploaded: "2024-09-01T23:00:28.796Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/0c1d0590-4aa3-4cd3-65d8-028f771b5c00/public",
        ],
      },
      {
        id: "b2ccec04-3621-4e6e-5c00-44ae45b77800",
        filename: "IMG-20240813-WA0150.jpg",
        uploaded: "2024-09-01T23:00:27.675Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/b2ccec04-3621-4e6e-5c00-44ae45b77800/public",
        ],
      },
      {
        id: "9e420d7a-5898-4b8f-3c9e-33c469352a00",
        filename: "IMG-20240813-WA0161.jpg",
        uploaded: "2024-09-01T23:00:27.547Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/9e420d7a-5898-4b8f-3c9e-33c469352a00/public",
        ],
      },
      {
        id: "5180debf-eca6-4e9f-a518-209fd741ce00",
        filename: "IMG-20240813-WA0160.jpg",
        uploaded: "2024-09-01T23:00:27.496Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/5180debf-eca6-4e9f-a518-209fd741ce00/public",
        ],
      },
      {
        id: "41fd0282-2049-4501-95d9-3c20947f1700",
        filename: "IMG-20240813-WA0154.jpg",
        uploaded: "2024-09-01T23:00:17.667Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/41fd0282-2049-4501-95d9-3c20947f1700/public",
        ],
      },
      {
        id: "8c99765a-d881-4d28-4eb0-7d870613f500",
        filename: "IMG-20240813-WA0175.jpg",
        uploaded: "2024-09-01T23:00:17.649Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/8c99765a-d881-4d28-4eb0-7d870613f500/public",
        ],
      },
      {
        id: "fd3d42ec-4e16-4d60-555b-6ccfb5eae900",
        filename: "IMG-20240813-WA0164.jpg",
        uploaded: "2024-09-01T23:00:17.291Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/fd3d42ec-4e16-4d60-555b-6ccfb5eae900/public",
        ],
      },
      {
        id: "0fd7a0c6-2c83-41f8-26ee-038ad5365900",
        filename: "IMG-20240813-WA0172.jpg",
        uploaded: "2024-09-01T23:00:17.048Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/0fd7a0c6-2c83-41f8-26ee-038ad5365900/public",
        ],
      },
      {
        id: "b95f8c99-6f89-49cf-853f-a7c308a44a00",
        filename: "IMG-20240813-WA0176.jpg",
        uploaded: "2024-09-01T23:00:16.771Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/b95f8c99-6f89-49cf-853f-a7c308a44a00/public",
        ],
      },
      {
        id: "a6fd1847-828d-430d-e0c6-027b86c63d00",
        filename: "IMG-20240813-WA0155.jpg",
        uploaded: "2024-09-01T23:00:16.454Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/a6fd1847-828d-430d-e0c6-027b86c63d00/public",
        ],
      },
      {
        id: "7c097c97-235f-4875-c7b8-969ba2abd800",
        filename: "IMG-20240813-WA0178.jpg",
        uploaded: "2024-09-01T23:00:16.215Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/7c097c97-235f-4875-c7b8-969ba2abd800/public",
        ],
      },
      {
        id: "a5ad147b-f7a8-42a5-1158-6057b8f98a00",
        filename: "IMG-20240813-WA0156.jpg",
        uploaded: "2024-09-01T23:00:15.522Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/a5ad147b-f7a8-42a5-1158-6057b8f98a00/public",
        ],
      },
      {
        id: "45691ee1-40ee-44ef-8542-489dad265800",
        filename: "IMG-20240813-WA0165.jpg",
        uploaded: "2024-09-01T23:00:15.107Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/45691ee1-40ee-44ef-8542-489dad265800/public",
        ],
      },
      {
        id: "10949e9f-aba7-4de5-900f-7489eeac2400",
        filename: "IMG-20240813-WA0162.jpg",
        uploaded: "2024-09-01T23:00:14.461Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/10949e9f-aba7-4de5-900f-7489eeac2400/public",
        ],
      },
      {
        id: "b6c0a914-0cff-4c6d-c968-dcfe688d1900",
        filename: "IMG-20240813-WA0171.jpg",
        uploaded: "2024-09-01T23:00:14.327Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/b6c0a914-0cff-4c6d-c968-dcfe688d1900/public",
        ],
      },
      {
        id: "671829af-a5dc-4277-67ce-b5cbbf8f6700",
        filename: "IMG-20240813-WA0177.jpg",
        uploaded: "2024-09-01T23:00:13.868Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/671829af-a5dc-4277-67ce-b5cbbf8f6700/public",
        ],
      },
      {
        id: "07cc87c5-8cd4-4ee7-6388-1842aea61b00",
        filename: "IMG-20240813-WA0163.jpg",
        uploaded: "2024-09-01T23:00:13.579Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/07cc87c5-8cd4-4ee7-6388-1842aea61b00/public",
        ],
      },
      {
        id: "aa55ba92-31e3-4763-61c9-cf1021844600",
        filename: "IMG-20240813-WA0166.jpg",
        uploaded: "2024-09-01T23:00:13.513Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/aa55ba92-31e3-4763-61c9-cf1021844600/public",
        ],
      },
      {
        id: "68f35d00-cdbd-4918-8d12-261a30a86100",
        filename: "IMG-20240818-WA0012.jpg",
        uploaded: "2024-09-01T23:00:13.272Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/68f35d00-cdbd-4918-8d12-261a30a86100/public",
        ],
      },
      {
        id: "5d9ace75-8b24-4cb9-66b6-5198b2151300",
        filename: "IMG-20240813-WA0157.jpg",
        uploaded: "2024-09-01T23:00:12.490Z",
        requireSignedURLs: false,
        variants: [
          "https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/5d9ace75-8b24-4cb9-66b6-5198b2151300/public",
        ],
      },
    ]);
  }, []);

  return { images };
}
