import { useState } from "react";


export function useSidenav() {
  const [sideNavOpen, setSideNavOpen] = useState(false);

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  return { sideNavOpen, toggleSideNav };
}